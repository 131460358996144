/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-debugger */
/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import { Col, DatePicker, Input, Popover, Row, Select, Skeleton, Spin, Table, Tag, notification } from 'antd';
import _ from 'lodash';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { AutoComplete } from '../../components/autoComplete/autoComplete';
import { Button } from '../../components/buttons/buttons';
import { CalendarButtonPageHeader } from '../../components/buttons/calendar-button/calendar-button';
import { Cards } from '../../components/cards/frame/cards-frame';
import { PageHeader } from '../../components/page-headers/page-headers';
import { API, errorParser } from '../../utility/utils';
import { UserTableStyleWrapper } from '../pages/style';
import { CardToolbox, DatePickerWrapper, Main, TableWrapper } from '../styled';
import FormItemLabel from 'antd/lib/form/FormItemLabel';
import FeatherIcon from 'feather-icons-react';
import { ItemWraper } from '../../components/datePicker/style';

moment.tz.setDefault('America/Mexico_City');

const Delays = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [reportsTableData, setReportsTableData] = useState([]);
  const [fetchingData, setFetchingData] = useState(true);
  const [searchWords, setSearchWords] = useState('');
  const [selectedProductStatus, setSelectedProductStatus] = useState(null);
  const [loadingEdit, setLoadingEdit] = useState(false);
  const [areas, setAreas] = useState([]);

  const [paginationData, setPaginationData] = useState({});
  const [filterBy, setFilterBy] = useState('CREATED_AT');
  const [loadingExport, setLoadingExport] = useState(false);
  const [dateRange, setDateRange] = useState(`${moment().format('DD/MM/YYYY')}-${moment().format('DD/MM/YYYY')}`);
  const [selectedOrderId, setSelectedOrderId] = useState(null);
  const [editedValue, setEditedValue] = useState({});
  const [filteredAreas, setFilteredAreas] = useState([]);

  const paymentListDisplayValue = {
    ABONADO: 'Abonado',
    CREDITO: 'Crédito',
    PAGO_CONTADO: 'Contado',
    TRANSFERENCIA: 'Transferencia',
    EFECTIVO: 'Efectivo',
    EFECTIVO_FACTURA: 'Efectivo Factura',
    EFECTIVO_RECIBO: 'Efectivo Recibo',
    CHEQUE: 'Cheque',
    TARJETA: 'Tarjeta',
    COT_SALDO_A_FAVOR: 'Cotizacion',
  };

  const formatStatus = {
    IN_PROGRESS: 'En progreso',
    PENDING: 'Pendiente',
    FINISHED: 'Finalizado',
    AUTHORIZED: 'Autorizado',
    AUTHORIZED_DIRECTOR: 'Autorizado por director',
  };

  const handleChange = value => {
    setFilteredAreas(value);
  };
  const autoCompleteRef = React.createRef();
  const filterTypes = {
    CREATED_AT: 'Fecha de creación',
    DELIVERED_TO_CLIENT: 'Fecha de entrega al cliente',
    DELIVERED_BY_ANALIST: 'Fecha de entrega por analista',
  };
  const statusList = [
    { value: 'ANALIST_DELIVERED', label: 'Entregado por analista', color: '#03fff1' },
    { value: 'SENT', label: 'Enviado', color: '#22b14c' },
    { value: 'PARTIAL', label: 'Parcial', color: '#b5e61c' },
    { value: 'DELAYED', label: 'Atrasado', color: '#fff302' },
    { value: 'DELAYED_SENT', label: 'Atraso enviado', color: '#ffc90d' },
    { value: 'CLIENT_DELAYED', label: 'Atraso cliente', color: '#ff0000' },
    { value: 'PENDING', label: 'Pendiente', color: '#ffffff' },
    { value: 'VA_CTES_TN', label: 'Va Ctes Tn', color: '#ffffff' },
    { value: 'DELETED', label: 'Eliminado', color: '#7092be' },
    { value: 'DELAYED_BY_CLIENT', label: 'Atraso por el cliente', color: '#ff0000' },
    { value: 'DELAYED_DELIVERED', label: 'Atraso entregado', color: '#b97a57' },
  ];

  const [date, setDate] = useState({
    start: moment()
      .startOf('day')
      .format('X'),
    end: moment()
      .endOf('day')
      .format('X'),
  });
  useEffect(() => {
    fetchReports();
    getAreas();
  }, []);

  const getAreas = async () => {
    const res = await API.get('areas');
    const arr = res.data.data.map(el => {
      return { value: el.id, label: el.area };
    });
    setAreas(arr);
  };
  const fetchReports = async () => {
    setFetchingData(true);
    const res = await API.get(
      `reports/delays?&page=${currentPage}&limit=50&startDate=${date.start}&endDate=${
        date.end
      }&search=${searchWords}&administrativeStatus=${selectedProductStatus || ''}&dateToFilter=${filterBy ||
        ''}&areasIds=${filteredAreas || ''}`,
    );
    const arr = [];
    res.data.data.results.forEach(u => {
      const today = moment().startOf('day');
      const formattedDate = u.deliveryDateAnalist.split(' ')[0]; // Splits the string and takes the date part
      const validDate = moment(formattedDate, 'DD/MM/YYYY').isValid() ? moment(formattedDate, 'DD/MM/YYYY') : null;
      const diff = validDate ? today.diff(validDate, 'days') : null;
      let color = '';
      if (diff !== null) {
        if (diff > 1) {
          color = '#f81d22';
        } else if (diff === 0) {
          // Same day
          color = '#faad14';
        } else if (diff < 0) {
          // Past date
          color = '#87d068';
        }
      }
      const formattedClientDate = u.deliveryDateClient.split(' ')[0]; // Splits the string and takes the date part
      const validClientDate = moment(formattedClientDate, 'DD/MM/YYYY').isValid()
        ? moment(formattedClientDate, 'DD/MM/YYYY')
        : null;
      const diffClient = validClientDate ? today.diff(validClientDate, 'days') : null;
      let colorClient = '';
      if (diffClient !== null) {
        if (diffClient > 1) {
          colorClient = '#f81d22';
        } else if (diffClient === 0) {
          colorClient = '#faad14';
        } else if (diffClient < 0) {
          colorClient = '#87d068';
        }
      }

      arr.push({
        ...u,
        alteredDeliveryDateClient: (
          <div style={{ width: 140, textAlign: 'center' }}>
            <Tag
              style={{ fontSize: '12px' }}
              color={moment(u.alteredDeliveryDateClient).isAfter(moment(u.deliveryDateClient)) ? '#f81d22' : '#87d068'}
            >
              {u.alteredDeliveryDateClient
                ? moment(u.alteredDeliveryDateClient.split(' ')[0], 'DD/MM/YYYY').format('DD/MM/YYYY')
                : null}
            </Tag>
          </div>
        ),
        deliveredToClientTimestamp: moment(u.deliveredToClientTimestamp).isValid()
          ? moment(u.deliveredToClientTimestamp).format('DD/MM/YYYY HH:mm a')
          : null,
        deliveryDateAnalist: (
          <div style={{ width: 140, textAlign: 'center' }}>
            <Tag style={{ fontSize: '12px' }} color={color}>
              {validDate.format('DD/MM/YYYY')}
            </Tag>
          </div>
        ),
        deliveryDateClient: (
          <div style={{ width: 140, textAlign: 'center' }}>
            <Tag style={{ fontSize: '12px' }} color={colorClient}>
              {validClientDate.format('DD/MM/YYYY')}
            </Tag>
          </div>
        ),
        administrativeStatus: u.administrativeStatus || 'N/A',
        key: uuidv4(),
      });
    });
    const paginationTemp = { ...res.data.data };
    delete paginationTemp.results;
    setPaginationData(paginationTemp);

    setReportsTableData(arr);

    setFetchingData(false);
  };

  const handleTableChange = pagination => {
    setCurrentPage(pagination.current);
  };

  const dataColumns = [
    { title: 'No. Muestra', dataIndex: 'folio', key: 'folio' },
    { title: 'Creado en', dataIndex: 'createdAt', key: 'createdAt' },
    { title: 'Cliente', dataIndex: 'client', key: 'client' },
    { title: 'Área', dataIndex: 'area', key: 'area' },
    { title: 'Producto', dataIndex: 'product', key: 'product' },
    { title: 'Fecha de Entrega Analista', dataIndex: 'deliveryDateAnalist', key: 'deliveryDateAnalist' },
    { title: 'Fecha de Entrega Cliente', dataIndex: 'deliveryDateClient', key: 'deliveryDateClient' },
    {
      title: 'Fecha de Entrega Modificada Cliente',
      dataIndex: 'alteredDeliveryDateClient',
      key: 'alteredDeliveryDateClient',
    },
    { title: 'Estado', dataIndex: 'status', key: 'status', render: (text, record) => <div>{formatStatus[text]}</div> },
    {
      title: 'Método de Pago',
      dataIndex: 'paymentMethod',
      key: 'paymentMethod',
      render: (text, record) => <div>{paymentListDisplayValue[text]}</div>,
    },
    {
      title: 'Estado Administrativo',
      dataIndex: 'administrativeStatus',
      key: 'administrativeStatus',
      render: (text, record) => renderPopover(record.workOrderAreaId, text, 'administrativeStatus', true, record),
    },
    {
      title: 'Recibido Administración',
      dataIndex: 'receivedByAdministration',
      key: 'receivedByAdministration',
    },
    {
      title: 'Entregado al cliente',
      dataIndex: 'deliveredToClientTimestamp',
      key: 'deliveredToClientTimestamp',
    },
    {
      title: '',
      dataIndex: '',
      key: '',
    },
  ];

  const handleEdit = async () => {
    setLoadingEdit(true);
    try {
      const { value } = editedValue;
      const payload = {
        status: value,
        workOrderAreaId: selectedOrderId,
      };
      console.log(payload);
      const res = await API.put(`reports/delays/update`, payload);
      setLoadingEdit(false);
      fetchReports();
      setSelectedOrderId(null);
      setEditedValue({});
      notification.success({ message: 'Hecho', description: 'Se han actualizado los datos' });
    } catch (e) {
      setLoadingEdit(false);
      notification.error({ message: 'Error', description: errorParser(e.response.data.error) });
    }
  };

  const handleExport = async () => {
    try {
      setLoadingExport(true);
      const res = await API.get(
        `reports/delays/export?&page=${currentPage}&limit=50&startDate=${date.start}&endDate=${
          date.end
        }&search=${searchWords}&administrativeStatus=${selectedProductStatus || ''}&dateToFilter=${filterBy ||
          ''}&areasIds=${filteredAreas || ''}`,
      );
      window.open(res.data.data.s3Key);
    } catch (e) {
      console.log(e);
    }
    setLoadingExport(false);
  };

  const getTextColor = backgroundColor => {
    const color = backgroundColor.charAt(0) === '#' ? backgroundColor.substring(1, 7) : backgroundColor;
    const r = parseInt(color.substring(0, 2), 16);
    const g = parseInt(color.substring(2, 4), 16);
    const b = parseInt(color.substring(4, 6), 16);
    return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? 'black' : 'white';
  };

  const { Option } = Select;
  const renderPopover = (workOrderId, text, key, select = false, record) => {
    const statusColor = statusList.find(x => x.value === record.administrativeStatus)
      ? statusList.find(x => x.value === record.administrativeStatus).color
      : '#f3f3f3';
    const statusLabel = statusList.find(x => x.value === record.administrativeStatus)
      ? statusList.find(x => x.value === record.administrativeStatus).label
      : 'N/A';

    return (
      <Popover
        className="popover-reports"
        content={
          <div style={{ marginTop: 10 }}>
            {select ? (
              <Select
                className="popover-reports"
                defaultValue={text}
                style={{ width: '12rem' }}
                onChange={value => {
                  setEditedValue({ value, key });
                }}
              >
                {statusList.map(status => (
                  <Option value={status.value} key={status.value}>
                    {status.label}
                  </Option>
                ))}
              </Select>
            ) : (
              <Input
                className="popover-reports"
                prefix={key === 'amount' ? '$' : null}
                placeholder="Importe"
                defaultValue={key === 'amount' ? text.substring(1) : text}
                onChange={e => {
                  setEditedValue({ value: e.target.value, key });
                }}
              />
            )}
            <Button
              className="btn-add_new"
              size="default"
              type="primary"
              loading={loadingEdit}
              disabled={loadingEdit}
              style={{ marginTop: 10, display: 'flex' }}
              onClick={handleEdit}
            >
              Guardar
            </Button>
          </div>
        }
        trigger="click"
        getPopupContainer={trigger => trigger.parentNode}
        overlayStyle={{ boxShadow: '0px 4px 20px rgba(0,0,0,0.15)', padding: 0 }}
      >
        <div
          onClick={() => {
            setSelectedOrderId(workOrderId);
          }}
          className="popover-text-td"
          style={{
            backgroundColor: statusColor,
            color: getTextColor(statusColor),
            textAlign: 'center',
            borderRadius: 4,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {statusLabel}
        </div>
      </Popover>
    );
  };

  useEffect(() => {
    fetchReports();
  }, [currentPage, searchWords, date, selectedProductStatus, currentPage, filteredAreas, filterBy]);

  const handleDateChange = newDate => {
    setDate({
      start: moment(newDate.selection.startDate)
        .startOf('day')
        .format('X'),
      end: moment(newDate.selection.endDate)
        .endOf('day')
        .format('X'),
    });

    const temp = `${moment(newDate.selection.startDate)
      .startOf('day')
      .format('DD/MM/YYYY')}-${moment(newDate.selection.endDate)
      .startOf('day')
      .format('DD/MM/YYYY')}`;
    setDateRange(temp);

    setCurrentPage(1);
  };

  const handleSearch = _.debounce(value => {
    setSearchWords(value);
    setCurrentPage(1);
  }, 500);
  return (
    <>
      <CardToolbox>
        <PageHeader
          ghost
          className="delaysHeader"
          title="Atrasos"
          subTitle={
            <div
              className="ordersContentBar delays"
              style={{ display: 'flex', alignItems: 'center', gap: 10, overflowX: 'auto', overflowY: 'clip' }}
            >
              {reportsTableData?.length > 0 && (
                <span className="title-counter">{`${paginationData.count} registros`} </span>
              )}
              <CalendarButtonPageHeader dateRange={dateRange} onChange={handleDateChange} />
              <Col span={4}>
                <AutoComplete
                  width="100%"
                  onSearch={handleSearch}
                  ref={autoComplete => {
                    autoCompleteRef.current = autoComplete;
                  }}
                  placeholder="Buscar ..."
                  patterns
                />
              </Col>
              <Col xs={4} md={4}>
                <Select
                  value={selectedProductStatus}
                  placeholder="Estado"
                  style={{ backgroundColor: '#fff', width: '100%' }}
                  onChange={value => {
                    setSelectedProductStatus(value);
                  }}
                >
                  {statusList.map(type => (
                    <Option value={type.value} key={type.value}>
                      {type.label}
                    </Option>
                  ))}
                </Select>
              </Col>
              <Col xs={4}>
                <Select
                  value={filterBy}
                  placeholder="Filtrar por..."
                  style={{ backgroundColor: '#fff', width: '100%' }}
                  onChange={value => {
                    setFilterBy(value);
                  }}
                >
                  {['CREATED_AT', 'DELIVERED_TO_CLIENT', 'DELIVERED_BY_ANALIST'].map(el => (
                    <Option value={el} key={el}>
                      {filterTypes[el]}
                    </Option>
                  ))}
                </Select>
              </Col>

              <Col span={4}>
                {areas.length > 0 ? (
                  <Select
                    mode="multiple"
                    allowClear
                    value={filteredAreas}
                    style={{ backgroundColor: '#fff', width: '100%' }}
                    placeholder="Filtrar por areas"
                    onChange={handleChange}
                    options={areas}
                  />
                ) : (
                  <Skeleton.Input active size="default" />
                )}
              </Col>
              {reportsTableData.length > 0 && (
                <Col>
                  <Button
                    className="btn-add_new"
                    size="default"
                    type="primary"
                    key="2"
                    loading={loadingExport}
                    disabled={loadingExport}
                    onClick={handleExport}
                  >
                    Exportar
                  </Button>
                </Col>
              )}
              {filterBy !== 'CREATED_AT' || filteredAreas.length > 0 || selectedProductStatus ? (
                <Button
                  className="btn-add_new"
                  size="default"
                  type="primary"
                  key="2"
                  onClick={() => {
                    setSelectedProductStatus(null);
                    setCurrentPage(1);
                    setFilteredAreas([]);
                    setFilterBy('CREATED_AT');
                  }}
                >
                  Borrar filtros
                </Button>
              ) : null}
            </div>
          }
        />
      </CardToolbox>

      <Main>
        <Row gutter={15}>
          <Col md={24}>
            <Cards headless>
              <UserTableStyleWrapper>
                <TableWrapper>
                  {fetchingData === false ? (
                    <Table
                    className='delaysTable'
                      dataSource={reportsTableData}
                      columns={dataColumns}
                      showSizeChanger={false}
                      locale={{ emptyText: 'No se encontraron resultados' }}
                      onChange={handleTableChange}
                      pagination={{
                        pageSize: 50,
                        current: paginationData.currentPage,
                        total: paginationData.count,
                        showTotal: (total, range) => `${range[0]}-${range[1]} de ${total} registros`,
                      }}
                    />
                  ) : (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <Spin size="large" />
                    </div>
                  )}
                </TableWrapper>
              </UserTableStyleWrapper>
            </Cards>
          </Col>
        </Row>
      </Main>
    </>
  );
};

export default Delays;
